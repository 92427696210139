<template>
  <div>
    <div class="row">
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-collapse withBackground>
          <span slot="header">{{ $t('layout.filters.label') }}</span>
          <template slot="body">
            <filters
              :loading="loading"
              :actions="filterActions"
              @filter="filterData"
              @clear-filters="filterData(null)"
            />
          </template>
        </va-collapse>
      </div>
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-card :title="$t('forms.index')">
          <remote-table
            class="table-hover table-striped"
            :columns="tableFields"
            :data="tableData"
            :loading="loading"
            :search="searchOptions"
            :pagination="pagination"
            :top-options="tableActions"
            :params="serverParams"
            :queries="serverParams.queries"
            sort-field="type"
            sort-type="asc"
            crud-links="forms"
            controller="DynamicForms"
            @update-data="updateData"
            @search-data="searchByName"
            @delete-item="tryDelete"
            @download-item="prepareDownload"
          />
        </va-card>
      </div>
    </div>

  </div>
</template>

<script>
const Filters = () => import(/* webpackPrefetch: true */ '@/components/extras/Filters')
const RemoteTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/RemoteTable')
export default {
  name: 'forms-index',
  components: {
    Filters, RemoteTable,
  },
  data () {
    return {
      error: false,
      tableData: [],
      pagination: {},
      searchQuery: '',
      loading: false,
      searchOptions: {
        enabled: true,
        trigger: 'enter',
        placeholder: this.$t('tables.actions.search'),
      },
      tableActions: [],
      totalTeams: 0,
      sort: {
        field: 'code',
        type: 'asc',
      },
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: 'code',
          type: 'asc',
        },
        queries: '',
        page: 1,
        perPage: 50,
      },
      filterActions: ['yearSince', 'yearUntil'],
    }
  },
  computed: {
    tableFields () {
      return [
        {
          name: 'type',
          title: this.$t('tables.headings.type'),
          sortField: 'type',
          callback: this.typeLabel,
        },
        {
          name: 'valid_since',
          title: this.$t('tables.headings.active_since'),
          sortField: 'valid_since',
          callback: this.dateLabel,
        },
        {
          name: 'valid_until',
          title: this.$t('tables.headings.active_until'),
          sortField: 'valid_until',
          callback: this.dateLabel,
        },
        {
          name: '__slot:actions',
          visible: this.tableData.length > 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
  },
  methods: {
    filterData (filters) {
      if (!filters) {
        this.serverParams.queries = ''
        return
      }

      let queries = ''
      if (filters.yearSince) {
        queries += '&year_since=' + filters.yearSince
      }
      if (filters.yearUntil) {
        queries += '&year_until=' + filters.yearUntil
      }

      this.serverParams.queries = queries
    },
    typeLabel (type) {
      let translation = ''
      switch (type) {
        case 0: translation = 'forms.inputs.types.team'; break
        case 1: translation = 'forms.inputs.types.district'; break
      }

      return this.$t(translation)
    },
    dateLabel (date) {
      return this.$date.format(date, 'MMMM - yyyy')
    },
    fixData (data) {
      return data
    },
    apiUrl (params) {
      let route = 'forms'
      route += '?page=' + params.page || 0
      route += '&limit=' + params.perPage || 50
      if (params.sort && params.sort.field !== '') {
        const field = params.sort.field
        route += '&sort=' + field
        route += '&direction=' + params.sort.type
      }
      if (params.queries) {
        route += params.queries
      }
      if (params.search) {
        route += '&q=' + params.search
      }

      return route
    },
    async updateData (params) {
      params = params || this.serverParams
      this.loading = true
      this.error = false

      let response = false
      try {
        response = await this.$http.get(this.apiUrl(params))
      } catch (err) {
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.loading = false
        this.error = true
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.pagination = response.data.pagination
      this.loading = false
    },
    async searchByName (name) {
      if (this.serverParams.search !== name) {
        this.serverParams.page = 1
      }
      this.serverParams.search = name
      this.loading = true
      this.error = false

      let response = false
      try {
        response = await this.$http.get(this.apiUrl(this.serverParams))
      } catch (err) {
        // console.log('Error searching mti', err)
        this.loading = false
        this.error = true
        return
      }
      if (this.serverParams.search !== name) {
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.pagination = response.data.pagination
      this.loading = false
    },
    async tryDelete (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })
      if (result.value !== true) return

      this.loading = true
      try {
        await this.$http.delete('forms/' + item.id)
      } catch (err) {
        // console.log('Error deleting form', err)
        this.loading = false
        return
      }
      this.updateData()
    },
    async prepareDownload (format) {
      const prepareRoute = 'forms/download'
      const downloadRoute = 'forms/download/'

      return this.downloadFile({ format: format }, prepareRoute, downloadRoute)
    },
    async downloadFile (filters, prepareRoute, downloadRoute) {
      let fileName = ''
      const prepare = {
        icon: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false
          try {
            data = await this.$http.post(prepareRoute, filters)
          } catch (e) {
            this.$swal.insertQueueStep(error)
            return
          }

          fileName = data.data.data
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const download = {
        icon: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          let response = false
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: 'blob',
            })
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          this.$swal.close()
        },
      }
      const retry = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.failed'),
        confirmButtonText: this.$t('notifications.download.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }

      this.$swal.queue([prepare])
    },
  },
}
</script>
